@font-face {
  font-family: 'UJI Display';
  src: url('./assets/fonts/UJIDISPLAY-BOLD.otf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
}


html,
body {
  width: 100vw;
  padding: 0;
  margin: 0;
  height: 100vh;
  overflow: hidden;
}


* {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  list-style: none;
  border: 0;
  font-family: 'Inter', Verdana, Geneva, Tahoma, sans-serif;
}

#body{
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}